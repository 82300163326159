const tabs = {
    get_started: 'はじめに',
    dashboard: 'ダッシュボード',
    applications: 'アプリケーション',
    api_resources: 'API リソース',
    sign_in_experience: 'サインイン',
    connectors: 'コネクタ',
    enterprise_sso: 'エンタープライズSSO',
    security: 'セキュリティ',
    webhooks: 'Webhooks',
    organizations: '組織',
    users: 'ユーザー管理',
    audit_logs: '監査ログ',
    roles: '役割',
    docs: 'ドキュメント',
    tenant_settings: '設定',
    mfa: 'Multi-factor auth',
    customize_jwt: 'カスタム JWT',
    signing_keys: '署名キー',
    organization_template: '組織テンプレート',
};
export default Object.freeze(tabs);
