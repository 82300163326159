const user_scopes = {
    descriptions: {
        custom_data: 'あなたのカスタムデータ',
        email: 'あなたのメールアドレス',
        phone: 'あなたの電話番号',
        profile: 'あなたの名前、ユーザー名、アバター、その他のプロフィール情報',
        roles: 'あなたのロール',
        identities: 'あなたのリンクされたソーシャルアイデンティティ',
        'urn:logto:scope:organizations': 'あなたの組織情報',
        'urn:logto:scope:organization_roles': 'あなたの組織の役割',
        address: 'あなたの住所',
    },
};
export default Object.freeze(user_scopes);
